<script setup lang="ts">
import {useAInesRequestStore} from "@/helper/chat/ainesRequests";
import {IonButton, IonIcon, IonItem, IonLabel, IonList} from "@ionic/vue";

const emit = defineEmits(["avatar:click", "request:click"]);

const store = useAInesRequestStore();

</script>

<template>
    <div class="aines-container boxed transparent floating-big no-padding-top no-padding-bottom">
        <div class="header" @click="emit('avatar:click')">
            <h2>ines</h2>
            <ion-button
                class="button-close"
                shape="round"
                fill="solid"
                color="light"
            >
                <ion-icon
                    color="secondary"
                    size="small"
                    src="/icons/x.svg"
                    slot="icon-only"
                />
            </ion-button>
        </div>
        <IonList >
            <IonItem lines="none" @click="emit('avatar:click')">
                <ion-label>
                    <h3 v-if="store.requests.length > 0">Hey, ich hab ein paar Sachen, die ich gerne mit dir besprechen würde:</h3>
                    <h3 v-else>Wenn ich etwas dringend habe, was wir besprechen sollten, dann taucht das hier auf. Aktuell habe ich keine Themen.</h3>
                </ion-label>

            </IonItem>
            <IonItem
                v-for="request in store.requests"
                @click="emit('request:click', request)"
                :detail = true
                lines="none"
                >
                <ion-label>
                    <p>{{ request.shortLabel }}</p>
                </ion-label>
            </IonItem>
        </IonList>
    </div>
</template>

<style scoped lang="scss">
.aines-container {
    position: absolute;
    top: 1em;
    left: calc(var(--custom-spacing-app-content-padding-horizontal) / 2);
    width: calc(100% - var(--custom-spacing-app-content-padding-horizontal));
    z-index: 10;
    background-color: var(--ion-color-white);

    > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: var(--ion-color-primary);
        }

        ion-button {
            width: 2em;
            height: 2em;
            flex: 0 0 2em;
            min-height: 0;
            margin-top: 1.2em;

            &::part(native) {
                padding: 0;
                min-height: 0;
            }
        }
    }

    h3, p {
        font-size: var(--custom-font-weight-regular);
    }

    h3 {
        font-weight: var(--custom-font-weight-medium);
    }

    p {
        color: var(--ion-color-grey);
    }
}

</style>
