<template>
    <div v-if="showPending" class="message with-avatar"><IonSpinner name="dots" /></div>
    <div v-else class="message with-avatar" v-html="content"></div>
</template>

<script setup lang="ts">
import MarkdownIt from "markdown-it"
import {computed, PropType} from "vue";
import {IonSpinner} from "@ionic/vue";

const props = defineProps({
    textContent: {
        type: String as PropType<String>,
        required: true
    },
    showPending: {
        type: Boolean as PropType<Boolean>,
        required: false,
        default: false
    },
})

const markdown = new MarkdownIt('zero', {
    breaks: false // css whitespace
})
    .enable(['emphasis','list'])

const content = computed(() => markdown.render(props.textContent))

</script>

<style scoped lang="scss">
.message {
    background-color: var(--ion-color-light);
    color: var(--ion-text-color);
    font-size: var(--custom-font-size-regular);
    line-height: 1.36em;
    margin: 0.5em 0 2em 0;
    padding: 1em;
    border-radius: 1rem;
    width: 100%;
    min-height: 3.36em;
    position: relative;
    transition-property: width;
    transition-duration: 0.25s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

    p {
        margin: 0;
        width: 100%;
    }

    // markdown-it
    // p, strong, ul, ol, li, em
    :deep(p) {
        white-space: pre-wrap; // respect the line breaks coming from the model
    }

    ion-spinner {
        height: 1em;
        line-height: 1em;
    }

    &.with-avatar {
        &:after {
            content: '';
            position: absolute;
            top: -0.5em;
            left: -0.5em;
            width: 1.5em;
            height: 1.5em;
            background-color: var(--ion-color-primary);
            background-image: url('/icon small.png');
            background-size: cover;
            border-radius: 50%;
        }
    }

    &.pending {
        width: 4em;
        display: inline-block;

        &:not(:first-child) {
            margin-top: -1.5em;
        }
    }
}
</style>
