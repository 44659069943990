import {AInesAssistantType} from "@/graphql/generated/graphql";
import {generateAdditionalInstructions} from "@/helper/chat/additionalInstructions";

export class AssistantRunConfig {
    public constructor(
        public readonly assistantType: AInesAssistantType,
        public readonly assistantId: string,
        public readonly threadId: string
    ) {}

    public getAdditionalInstructions = async () => await generateAdditionalInstructions()
}
