<template>
    <div class="chat-text-input floating-big-pseudo">
        <IonTextarea
            auto-grow
            v-model="text"
            :rows="1"
            @input="onInput"
            @keypress.enter="onSubmit"
            placeholder="Frag ines!"
            class="fragAInesInput"
        />
        <InlineSVG
            @click="onSubmit"
            class="submit-button"
            :class="{'active': text.length > 0}"
            src="/icons/arrow-circle-right.svg"
        />
    </div>
</template>

<script setup lang="ts">
import {ref} from "vue";
import InlineSVG from "@/components/InlineSVG.vue";
import {IonInput, IonTextarea} from "@ionic/vue";

const emit = defineEmits(["text:submit", "text:input"]);

const text = ref('')
const props = defineProps({
    disabled: {
        type: Boolean,
        default : false,
        required : false,
    }
})

const onInput = () => {
    emit("text:input", text.value.trim());
};

const onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (text.value.trim() !== "" && !props.disabled) {
        emit("text:submit", text.value.trim());
        text.value = "";
    }
};

const clear = () => {
    text.value = "";
}
defineExpose({clear})
</script>

<style scoped lang="scss">
.fragAInesInput {
    color: var(--ion-color-secondary);
}
.chat-text-input {
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.15em;
    padding-bottom: 0.15em;
    border-radius: 1rem;
    z-index: 9;
    background-color: var(--ion-color-white);

    ion-input {
        flex: 1;
        border: none;
        outline: none;
    }

    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--ion-color-secondary);
    }

    .inline-svg {
        width: 2rem;
        height: 2rem;
        padding: .3rem;
    }

    .submit-button {
        width: 2.5rem;
        height: 2.5rem;

        color: var(--ion-color-grey);

        &.active {
            color: var(--ion-color-primary);
        }
        
    }
}
</style>
