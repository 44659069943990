import mitt, {Emitter} from 'mitt';
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {AvailabilityPlanningDates, Candidate} from "@/graphql/generated/graphql";
import {ChatSessionConfig} from "@/helper/chat/chatStore";
import {AppState} from "@/helper/appState";

export type GlobalEvents = {
    AppStateChanged: {state: AppState, impersonationUserSwitch: Boolean},
    "day:mutated" : kapitelDateString,
    "autopilotPreferences:mutated": kapitelDateString,
    "availabilityPlanningDates:mutated": AvailabilityPlanningDates,
    "availabilityPlanningMonth:mutated": kapitelDateString,
    "notification:clicked": kapitelDateString
    "VoiceModeNewTextMessage" : string,
    ChatSessionConfigUpdated: ChatSessionConfig,
    CandidateUpdated: Candidate
}

const emitter = mitt();
const globalEmitter = mitt<GlobalEvents>()

export default function useEmitter(): Emitter<any> {
    return emitter;
}

export function useGlobalEmitter(): Emitter<GlobalEvents> {
    return globalEmitter;
}
