<template>
    <ion-page class="page-auth">
        <ion-content padding="true">
            <ion-loading
                :is-open="loader"
                message="Gleich ist es soweit..."
            />
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">

import {setUpCandidate} from "@/helper/candidate";
import {IonContent, IonLoading, IonPage} from "@ionic/vue";
import {ref} from "vue";
import {useChatStore} from "@/helper/chat/chatStore";

const loader = ref(true)

setUpCandidate().then(()=>{
    loader.value = false
    const chatStore = useChatStore()
    chatStore.setIsFirstContact(true)
})

</script>

<style lang="scss" scoped>

// contact modal auto height
ion-modal {
    --height: auto;
}

.page-auth {
    ion-content {
        &::part(scroll) {
            padding-top: var(--ion-safe-area-top);
            padding-bottom: var(--ion-safe-area-bottom);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .centered-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
            width: 25vw;
            margin-bottom: 3rem;
        }
    }

    .boxed {
        margin-bottom: 2rem;
    }
}
</style>
