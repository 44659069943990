<template>
    <div class="flex-wrapper" ref="root">
        <iframe ref="iframe" />
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const root = ref(null)
const iframe = ref(null)

const updateWrappingState = () => {
    const rootElement = root.value as never as HTMLElement
    const children = rootElement?.children

    if (!children) {
        return;
    }

    for (let i = 1; i < children.length; i++) {
        const child = children[i] as never as HTMLElement
        const previousSibling = children[i - 1] as never as HTMLElement

        if (!previousSibling || previousSibling.tagName === 'IFRAME') {
            child.classList.add('flex-wrapped')
            continue
        }
        
        const childOffsetTop = child.offsetTop
        const previousSiblingOffsetTop = previousSibling.offsetTop

        if (childOffsetTop > previousSiblingOffsetTop) {
            child.classList.add('flex-wrapped')
        }
        else {
            child.classList.remove('flex-wrapped')
    }
}
}

onMounted(() => {
    const frame = iframe.value as never as HTMLIFrameElement

    if (!frame) {
        console.info('no iframe found in flex wrapper')
        return
    }

    const iframeWin = frame.contentWindow 

    if (!iframeWin) {
        console.info('no iframe window found in flex wrapper')
        return
    }
    
    iframeWin.addEventListener('resize', updateWrappingState)
    updateWrappingState()
})
</script>

<style lang="scss" scoped>
.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    iframe {
        border: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>