import { mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import { Demand } from "@/graphql/generated/graphql";
import { jobsiteFragment } from "./jobsite";
import {kapitelDateString} from "@/graphql/kapitelTypes";



export async function removeWishDemand(demand: Demand): Promise<any> {

    const MUTATION = gql`
            mutation RemoveWishDemand (
                $demandIdDings: Int!
            ) {
                removeWishDemand(
                    demandId: $demandIdDings,
                )
            }`

    const result = await mutation(
        MUTATION, {
            demandIdDings: demand.id,
        }
    )

    const response = result?.data?.removeWishDemand

    if (!response) {
        return 'Fehler beim Speichern'
    }

    return response
}
