<template>
    <ion-page class="page-offline">
        <ion-content fullscreen padding="true">

            <!-- Logo -->

            <ion-row class="ion-justify-content-between ion-align-items-center">
                <img class="logo" src="/logo.png" alt="ines Logo">
            </ion-row>

            <!-- Hello -->

            <h1 class="custom-ricarda" v-if="employee?.name">
                Hallo<br>{{ employee.name?.split(' ')[0] }}.
            </h1>

            <template v-if="!offlineHelper.isOnline.value">
                <div class="text-center">
                    <InlineSVG src="/icons/globe-x.svg" class="icon-offline" />
                    <h3>
                        Du bist offline.
                    </h3>
                </div>
                <p class="boxed error">
                    Du scheinst aktuell offline zu sein oder eine eingeschränkte Internetverbindung zu haben. Die App befindet sich deswegen im Offline-Modus.
                    <br><br>
                    Die Zeiterfassung kann jedoch auch offline jederzeit gestartet werden.
                </p>
                <p class="boxed text-center">
                    Wir versuchen im Hintergrund die Internetverbindung wiederherzustellen.
                </p>
                <ion-spinner v-show="offlineHelper.isOffline.value" class="retry-spinner" name="dots" />

                <TimesheetProcess v-model="openTimesheetProcessModal"/>

            </template>

            <template v-else>
                <div class="text-center">
                    <InlineSVG src="/icons/globe-check.svg" class="icon-online" />
                    <h3>
                        Du bist wieder online!
                    </h3>
                </div>
                <p class="boxed success">
                    Die Internetverbindung wurde wiederhergestellt. Du kannst die App wieder wie gewohnt nutzen.
                </p>
            </template>
            
        </ion-content>
        <!-- actions (fixed slot ) -->
        <ion-footer class="ion-padding safe-area-bottom">
                <ion-button color="success" @click="exitOfflineMode" v-if="offlineHelper.isOnline.value" expand="block">
                    Zurück zur App
                </ion-button>
                <ion-button v-else color="secondary" @click="openTimesheetProcessModal = true" expand="block">
                    Zeiterfassung starten
                </ion-button>
            </ion-footer>
    </ion-page>
</template>

<script setup lang="ts">
import {IonButton, IonContent, IonFooter, IonPage, IonRow, IonSpinner, onIonViewDidEnter} from '@ionic/vue';

import {useRouter} from 'vue-router';
import useOfflineHelper from "@/helper/offline";
import {ref, Ref} from "vue";
import {Employee} from "@/graphql/generated/graphql";
import {DataLoader} from "@/helper/dataLoader";

import {useEmployeeStore} from "@/store/employee";
import {onIonViewEnteredAndCapicatorAppActivate} from "@/helper/onIonViewEnteredAndCapicatorAppActivate";

import InlineSVG from '@/components/InlineSVG.vue';
import TimesheetProcess from "@/views/Timesheet/TimesheetProcess.vue";

const router = useRouter()

const offlineHelper = useOfflineHelper()

const employee:Ref<Employee|undefined> = ref(undefined)
const employeeLoader = new DataLoader(async () => { employee.value = await useEmployeeStore().getEmployee() })
employeeLoader.load()

const exitOfflineMode = () => router.replace('/')

const openTimesheetProcessModal: Ref<boolean|undefined> = ref()

/** 
 * events 
*/
const exitOfflineModeIfOnline = () => {
    if (offlineHelper.isOnline.value) {
        exitOfflineMode()
    }
}
onIonViewDidEnter(exitOfflineModeIfOnline)
onIonViewEnteredAndCapicatorAppActivate(async () => {
    await offlineHelper.checkImmediately()
    exitOfflineModeIfOnline()
})

const modalValue: Ref<boolean|undefined> = ref(undefined)

const  onClickTimesheet = ()=>{
    modalValue.value = true
}



</script>

<style lang="scss">
.page-offline {
    padding-top: var(--ion-safe-area-top);

    .logo {
        width: 12vw;
        margin-bottom: 0.5em;
    }

    p {
        text-align: justify;
    }

    .icon-offline,
    .icon-online {
        width: 4rem;
        animation: pulse-offline-icon 2s infinite;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .icon-offline {    
        color: var(--ion-color-primary);
        filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-primary-rgb), 0.4));
    }

    .icon-online {
        color: var(--ion-color-success);
        filter: drop-shadow( 0 0 0.4rem rgba(var(--ion-color-success-rgb), 0.4));
    }

    .retry-spinner {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        height: 2rem;
    }

    @keyframes pulse-offline-icon {
        0%, 100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
    }

    ion-footer {
        background-color: var(--ion-color-white);
    }
}
</style>
