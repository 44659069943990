import {defineStore} from "pinia";
import {AssistantRunExpert} from "@/helper/chat/assistantRun/assistantRunExpert";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {RichContent} from "@/helper/chat/richResponses";
import {KapitelToolCall, loadingMessageFor} from "@/helper/chat/toolCalls";
import {StreamingThreadMessageChanges, ThreadMessage, ThreadMessageStatus} from "@/helper/chat/threadMessage";
import {TranscribeStatus} from "@/helper/chat/speechToTextRecorder";
import {AssistantRunStatus} from "@/helper/chat/assistantRun/assistantRunStatus";
import {TextToSpeech} from "@/helper/chat/textToSpeech";
import {useGlobalEmitter} from "@/helper/emitter";
import {AssistantRunChooser} from "@/helper/chat/assistantRun/assistantRunChooser";
import {v4 as uuidv4} from "uuid";

const globalEmitter = useGlobalEmitter()

export interface ChatSessionConfig {sessionId: string, pingPongId: string}

export const useChatStore = defineStore("Chat", {
    persist: {
        paths: ['voiceOrTextMode']
    },
    state: (): {
        sessionId: string,
        pingPongId: string,

        pingPongProcessing: boolean,
        chatStatus: ChatStatus,

        currentChooserRun: AssistantRunChooser | undefined,

        currentExpertRun: AssistantRunExpert | undefined,
        currentExpertRunMessageTextStreaming: string,
        currentExpertRunMessageRichContents: RichContent[] | undefined,
        currentExpertRunMessageStatus: ThreadMessageStatus,
        currentExpertRunMostRecentToolCall: KapitelToolCall | undefined,
        currentExpertRunMessageRichContentsPreview: RichContent[] | undefined,

        messageHistory: ThreadMessage[],

        ttsStatus: 'silent'|'speaking',
        ttsObjects: TextToSpeech[],

        transcribeStatus: TranscribeStatus,
        voiceOrTextMode: 'voice'|'text',

        isFirstContact_: boolean,

        globalLoaderState: boolean | string
    } => ({
        sessionId: createSessionId(),
        pingPongId: createPingPongId(),

        pingPongProcessing: false,
        chatStatus: ChatStatus.READY,

        currentChooserRun: undefined,

        currentExpertRun: undefined,
        currentExpertRunMessageTextStreaming: '',
        currentExpertRunMessageRichContents: undefined,
        currentExpertRunMessageStatus: 'ready',
        currentExpertRunMostRecentToolCall: undefined,
        currentExpertRunMessageRichContentsPreview: undefined,

        messageHistory: [],
        ttsStatus: 'silent',
        ttsObjects: [],

        transcribeStatus: 'ready',
        voiceOrTextMode: 'text',
        isFirstContact_: false,

        globalLoaderState: false
    }),
    getters: {
        sessionConfig: (state) : ChatSessionConfig => ({sessionId:state.sessionId, pingPongId: state.pingPongId}),
        userMessageHistoryEmpty: (state) => state.messageHistory.filter(message => message.role === 'user').length === 0,
        currentPreprocessedAssistantMessageStreaming: (state) : PreprocessedAssistantMessage | undefined => {
            if (state.currentExpertRunMessageTextStreaming || state.currentExpertRunMessageRichContents || state.currentExpertRunMessageRichContentsPreview) {
                return {
                    textContent: state.currentExpertRunMessageTextStreaming || undefined,
                    richContents: state.currentExpertRunMessageRichContents || state.currentExpertRunMessageRichContentsPreview || undefined
                }
            }
            return undefined
        },
        nothingHappenedYet() : boolean {
            return this.userMessageHistoryEmpty && !this.currentExpertRun && ChatStatus.isResting(this.chatStatus) && TranscribeStatus.isResting(this.transcribeStatus)
        },
        isBlocked() : boolean {
            return this.pingPongProcessing
        },
        isVoiceMode() : boolean {
            return this.voiceOrTextMode === 'voice'
        },
        isTextMode() : boolean {
            return this.voiceOrTextMode === 'text'
        },
        isFirstContact(state): boolean {
            return state.isFirstContact_
        },
        currentExpertRunMostRecentToolCallLoaderMessage: (state) => {
            const toolCall = state.currentExpertRunMostRecentToolCall

            if (toolCall) {
                return loadingMessageFor(toolCall)
            }
        }
    },
    actions: {
        resetSession () {
            this.sessionId = createSessionId()
            this.pingPongId = createPingPongId()
            globalEmitter.emit("ChatSessionConfigUpdated", this.sessionConfig)
        },
        newPingPong () {
            this.pingPongId = createPingPongId()
            globalEmitter.emit("ChatSessionConfigUpdated", this.sessionConfig)
        },
        setIsFirstContact(isFirstContact : boolean) {
            this.isFirstContact_=isFirstContact
        },
        pushTtsObjects(ttsObject: TextToSpeech){
            this.ttsObjects = this.ttsObjects.concat(ttsObject)
        },
        pullTtsObject(): TextToSpeech | undefined {
            return this.ttsObjects.shift()
        },
        resetTtsObjects(): void {
            this.ttsObjects = []
        },
        setTtsStatus(status: "silent"|"speaking"){
            this.ttsStatus = status
        },
        setPingPongProcessing(state: boolean) {
            this.pingPongProcessing = state
        },
        setChatStatus(state: ChatStatus, force: boolean = false) {
            const nextState = ChatStatus.transitionTo(state, this.chatStatus, force)

            if (nextState === this.chatStatus) {
                return;
            }

            // consoleLogChat('ChatStatus -> %o', ChatStatus.stringify(state))
            this.chatStatus = state
        },
        setVoiceOrTextMode(mode:'voice'|'text'):void
        {
            this.voiceOrTextMode = mode
        },
        setChatStatusFromRunState(state: AssistantRunStatus) {
            this.setChatStatus(ChatStatus.fromAssistantRunState(state))
        },
        setCurrentChooserRun(run: AssistantRunChooser | undefined) {
            this.currentChooserRun = run
        },
        resetCurrentExpertRun() {
            if (this.currentExpertRun) {
                this.currentExpertRun.mitt.all.forEach((v,k) => k === '*' ? console.error('wildcard event not handled') : this.currentExpertRun?.mitt.off(k))
                this.currentExpertRun.mittExpert.all.forEach((v,k) => k === '*' ? console.error('wildcard event not handled') : this.currentExpertRun?.mittExpert.off(k))
            }

            this.currentExpertRun = undefined
            this.setChatStatus(ChatStatus.READY)

            /*
            reactivity workaround
             */

            // reset message copy
            this.currentExpertRunMessageTextStreaming = ''
            this.currentExpertRunMessageRichContents = undefined
            this.currentExpertRunMessageStatus = 'ready'

            // reset tool call
            this.currentExpertRunMostRecentToolCall = undefined
            this.currentExpertRunMessageRichContentsPreview = undefined
        },
        setCurrentExpertRun(run: AssistantRunExpert) {
            this.currentExpertRun = run;

            // init & track run state
            this.setChatStatusFromRunState(run.getState())
            run.mitt.on('stateChanged', (state) => this.setChatStatusFromRunState(state))

            // collect response message (even if not yet finished, even it might change over time!)
            this.addToMessageHistory(run.message)


            /*
            reactivity workaround
             */

            // init message copy and track state
            const _setMessageContent = (changes : StreamingThreadMessageChanges) => {
                if ('text' in changes) this.currentExpertRunMessageTextStreaming = run.message.text
                if ('richResponses' in changes) this.currentExpertRunMessageRichContents = run.message.getRichContents()
                if ('status' in changes) this.currentExpertRunMessageStatus = run.message.status
            }
            _setMessageContent(run.message)
            run.mittExpert.on('messageStreaming', _setMessageContent)

            // init tool call copy and track state
            this.currentExpertRunMostRecentToolCall = run.getMostRecentToolCall()
            run.mitt.on('runRequiresToolCall', (toolCall) =>  this.currentExpertRunMostRecentToolCall = toolCall)


            /*
            Voice mode events
             */

            if (run.message.isTextComplete()) {
                globalEmitter.emit('VoiceModeNewTextMessage', run.message.text)
            }
            run.mittExpert.on('messageStreaming', (changes : StreamingThreadMessageChanges) => {
                if ('status' in changes && run.message.status === 'textDone') {
                    globalEmitter.emit('VoiceModeNewTextMessage', run.message.text)
                }
            })
            run.mitt.on('runRequiresToolCalls', () => {
                if (this.currentExpertRunMostRecentToolCallLoaderMessage) {
                    globalEmitter.emit('VoiceModeNewTextMessage', this.currentExpertRunMostRecentToolCallLoaderMessage)
                } else {
                    console.warn('no loader message (yet?)')
                    debugger
                }
            })
        },
        setCurrentExpertRunRichContentsPreview(richContents: RichContent[]) {
            this.currentExpertRunMessageRichContentsPreview = richContents
        },
        addToMessageHistory(message: ThreadMessage) {
            this.messageHistory.push(message)
        },
        resetMessageHistory(){
            this.messageHistory = []
        },
        setTranscribeStatus(state: TranscribeStatus) {
            this.transcribeStatus = state
        },
        setGlobalLoaderState(state: boolean, message?: string) {
            if (!state) {
                this.globalLoaderState = false
            } else {
                this.globalLoaderState = message || 'Einen Moment bitte...'
            }
        }
    }
})

export interface PreprocessedAssistantMessage {
    textContent: string | undefined,
    richContents: RichContent[] | undefined
}

const createSessionId = () => {
    return 'sess_' + uuidv4()
}

const createPingPongId = () => {
    return "pipo_" + uuidv4()
}
