<script setup lang="ts">
import { ref, computed, watch, PropType } from "vue";
import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonLabel,
    IonList,
    IonItem,
} from "@ionic/vue";
import { orderedShiftTypes } from "@/helper/shiftTypes";
import InlineSVG from "@/components/InlineSVG.vue";
import date from "@/helper/datetime/date";
import AvailabilityEffectiveInfo from "@/components/AvailabilityEffectiveInfo.vue";
import AbsenceEditor from "@/components/AbsenceEditor.vue";
import AvailabilityConstraintEditor from "@/components/AvailabilityConstraintEditor.vue"
import { Availability, Absence, AvailabilityShift, ShiftType, AvailabilityShiftDefault } from "@/graphql/generated/graphql";
import {
    generateAvailability,
    generatePropertiesFromDefault
} from '@/helper/availability'
import { getAbsenceTypes, generateAbsence, defaultAbsenceType } from '@/helper/absence'
import { setAvailabilityAbsence } from '@/helper/availabilityAbsence'
import {fetchAvailabilityShiftDefaults} from '@/helper/availabilityShiftDefault'
import {DataLoader} from "@/helper/dataLoader";
import {fetchDayForDate} from "@/helper/day";
import Skeleton from "@/components/Skeleton.vue";

const props = defineProps({
    availability: {
        type: Object as PropType<Availability>,
        default: undefined,
        required: false,
    },
    absence: {
        type: Object as PropType<Absence>,
        default: undefined,
        required: false,
    },
    // special for template
    date: {
        type: String,
        default: undefined,
        required: false,
    },
});

const emit = defineEmits(['set:availability', 'set:absence'])

const localAvailability = ref<Availability|undefined>(undefined)
const localAbsence = ref<Absence|undefined>(undefined)

// const editItem = ref<Availability|Absence|undefined>(undefined)
const availabilityShiftDefaults = ref()

watch(() => props.availability, value => {
    localAvailability.value = value
})

watch(() => props.absence, value => {
    localAbsence.value = value
})

const whichViewToRender = computed(()=>{
    if(localAbsence.value){
        return "AbsenceEditor"
    }
    return "AvailabilityConstraints"
})


const getIndexOfShift = (availability: Availability, shiftType: ShiftType): number => availability.shifts.findIndex(sh => sh.type === shiftType)
const isShiftActive = (shift:ShiftType) => !localAbsence.value && localAvailability.value && getIndexOfShift(localAvailability.value, shift) > -1

const onClickShift = async (shift: ShiftType)=>{

    if(!props.date){
        throw new Error("No date given, unable to generate Availabilty.")
    }

    if(localAbsence.value){
        localAbsence.value=undefined
    }

    if(!localAvailability.value){
      // we are on a specific date and don't have an editItem -> generate availability
        localAvailability.value = generateAvailability(props.date)
    }

    const index = getIndexOfShift(localAvailability.value, shift)

    if (index > -1) {
        localAvailability.value.shifts.splice(index, 1)
    } else {

        const shiftDefault = availabilityShiftDefaults.value.find((shiftDef:AvailabilityShiftDefault) => shiftDef.type === shift)
        const newShift = generatePropertiesFromDefault(props.date, shiftDefault)

        localAvailability.value.shifts.push(newShift)
    }
    await save(localAvailability.value, null)
    emit("set:availability", localAvailability.value)

}

const onClickAbsence = async () => {

    if(!props.date){
        throw new Error("No date given, unable to generate Absence/Availability.")
    }

    if(localAbsence.value){
        localAbsence.value=undefined

        if(!localAvailability.value){
            localAvailability.value = generateAvailability(props.date)
        }

        await save(localAvailability.value, null)
        emit("set:availability", localAvailability.value)

    }else{
        localAbsence.value = generateAbsence(props.date, defaultAbsenceType)
        localAvailability.value = undefined

        await save(null, localAbsence.value)
        emit("set:absence", localAbsence.value)

    }
}

const updateAbsence = async (newAbsence:Absence)=>{
    localAbsence.value = newAbsence
    await save(null, localAbsence.value)
    emit("set:absence", localAbsence.value)
}

const updateAvailability = async (newAvailability: Availability) => {
    localAvailability.value = newAvailability
    await save(localAvailability.value, null)
    emit("set:availability", localAvailability.value)
}

const save = async (availability: Availability|null, absence: Absence|null) => {
    const day = await setAvailabilityAbsence(availability, absence, "availability {effectiveJobsites {id}, differsFromTemplate}")

    if (localAvailability.value && day.availability) {
        localAvailability.value.differsFromTemplate = day.availability.differsFromTemplate
        localAvailability.value.effectiveJobsites = day.availability.effectiveJobsites
    }
}

const loader = new DataLoader([
    async () => {
        availabilityShiftDefaults.value = await fetchAvailabilityShiftDefaults()
    }
]);


localAvailability.value = props.availability ? JSON.parse(JSON.stringify(props.availability)) : undefined
localAbsence.value = props.absence ? JSON.parse(JSON.stringify(props.absence)): undefined
loader.load()

</script>

<template>
    <h3 style="text-transform: uppercase">Verfügbarkeit</h3>
    <br>
    <AvailabilityEffectiveInfo
        :shifts="!localAbsence && localAvailability ? localAvailability.shifts: []"
        :constraints="!localAbsence && localAvailability ? localAvailability.constraints: []"
        :label="date.formatKapitelDate(props.date, 'DD')"
        :jobsiteCount="localAvailability?.effectiveJobsites?.length"
    />

   <!-- <p class="text-medium" id="differs">
        {{ localAvailability?.differsFromTemplate ? "Diese Zeiten weichen von der Vorlage ab." : "" }}
    </p>--> 

    <div class="shift-toggler" v-if="!loader.pending.value">
        <ion-button
            class="icon"
            v-for="shift in orderedShiftTypes"
            :color="isShiftActive(shift) ? 'secondary' : 'light'"
            v-on:click="onClickShift(shift)"
            :key="shift"
            >
            {{ shift }}
        </ion-button>
        <ion-button
            color="light"
            class="shift-absence-button"
            :class="{'has-absence': localAbsence}"
            v-on:click="onClickAbsence()"
            >
            <InlineSVG class="inline-svg" src="/icons/prohibit.svg" />
        </ion-button>
    </div>
    <Skeleton
        v-if="loader.pending.value"
        :items="['block--height-4rem', 'block--height-4rem']"
    ></Skeleton>

    <AvailabilityConstraintEditor
        v-if="whichViewToRender=='AvailabilityConstraints' && !loader.pending.value"
        :availability="localAvailability"
        v-on:update:availability="updateAvailability"
    />

    <AbsenceEditor
        v-if="whichViewToRender=='AbsenceEditor' && !loader.pending.value"
        :absence="localAbsence"
        v-on:update:absence="updateAbsence"
        :date="props.date"
    />
</template>
<style scoped lang="scss">
.shift-toggler {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
        flex: 0 1 22%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .shift-absence-button {
        color: var(--ion-color-primary);

        .inline-svg {
            width: 1.25em;
            color: var(--ion-color-primary);
            svg {
                filter: drop-shadow(
                    0 0 0.4rem rgba(var(--ion-color-primary-rgb), 0.4)
                );
            }
        }

        &.has-absence {
            .inline-svg {
                color: var(--ion-color-light);
            }

            &::part(native) {
                background-color: var(--ion-color-primary);
                color: var(--ion-color-light);
            }
        }
    }
}

#differs:not(:empty) {
    $LINE_HEIGHT: 1.125em;
    line-height: $LINE_HEIGHT;
    min-height: 2 * $LINE_HEIGHT;
}

</style>
