<template>
    <RichResponseButton
        title="Benachrichtigen lassen"
        v-on:click="onClick"
    />
    <CandidateMissingJobsiteTypeModal ref='modal' v-model="modalValue"  v-on:submit="onSubmit" :missing-jobsite-type="initData"/>
</template>

<script setup lang="ts">
import {ref, Ref} from "vue";
import {updateCandidateOnMissingJobsiteType} from "@/helper/candidate";
import {sendUserMessage} from "@/helper/chat/chatBL";
import {useChatStore} from "@/helper/chat/chatStore";
import RichResponseButton from "@/views/Chat/RichResponses/components/RichResponseButton.vue";
import CandidateMissingJobsiteTypeModal from "@/views/components/CandidateMissingJobsiteTypeModal.vue";

const modal = ref()
const modalValue: Ref<boolean> = ref(false)
const onClick = () => {
    openModal()
}

const openModal = () => {
    modalValue.value = true
    modal.value.init()
}

const props = defineProps<{
    initData: String | undefined
}>()


const onSubmit = (inputData: any) => {

    const name = inputData.fullName  ? inputData.fullName : ""
    const contact = inputData.phoneNumber  ? inputData.phoneNumber : ""
    const interestedInMissingJobsiteType = inputData.interestedInMissingJobsiteType ? inputData.interestedInMissingJobsiteType : ""

    useChatStore().setGlobalLoaderState(true, 'Eine Sekunde noch...')

    updateCandidateOnMissingJobsiteType(
        name,
        contact,
        interestedInMissingJobsiteType
    )
        .then(()=>{
            sendUserMessage(
                "Vielen Dank, cch habe meine Kontaktdaten angegeben und freue mich darauf, dass ich kontaktiert werde sobald meine gewünschte Stationskategorie dazu kommt.",
                {
                    isScriptedContent: true
                }
            )
        })
        .finally(() => {
            useChatStore().setGlobalLoaderState(false)
        })
}

const init = () =>{
    openModal()
}


</script>
