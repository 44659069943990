import {performToolCallRequest} from "@/helper/chat/assistantAPI";
import {useCandidateStore} from "@/store/candidate";
import {onCandidateInterestedInSet} from "@/helper/chat/chatBL";
import {AInesToolCallType} from "@/graphql/generated/graphql";

export type KapitelToolCall = {
    id: string,
    function: string,
    arguments: string
}

export const isWriting = (toolCall: KapitelToolCall) => {
    return !toolCall.function.startsWith('get');
}

const _getLoadingMessagesFor = (toolCall: KapitelToolCall) => {
    switch (toolCall.function) {
        case 'setEmployeeQualification': return 'Alles klar, das merke ich mir.'
        default: return isWriting(toolCall)
            ? [
                'Einen Moment Geduld bitte, ich bin dabei.',
                'Ich mach das für dich.'
            ]
            : [
                'Ich schaue kurz nach.',
                'Eine Sekunde, ich muss nachsehen.'
            ]
        }
}
export const loadingMessageFor = (toolCall: KapitelToolCall) => {
    let message = _getLoadingMessagesFor(toolCall)

    return Array.isArray(message)
        ? message[Math.floor(Math.random()*message.length)]
        : message
}


// Define types for the hooks
type ToolCallHook = (args: any) => void;
type LocalToolCallHook = (args: any) => Promise<any>;

type ToolCallHooks = {
    [key in AInesToolCallType]?: ToolCallHook;
}

type LocalToolCallHooks = {
    [key in AInesToolCallType]?: LocalToolCallHook;
}

// Local handling hooks to replace API calls when necessary
const localToolCallHooks: LocalToolCallHooks = {
};

// Post-call hooks for executing additional actions
const postToolCallHooks: ToolCallHooks = {
    [AInesToolCallType.SetCandidateInterestedIn]: async () => {
        await onCandidateInterestedInSet()
    },
    [AInesToolCallType.AddJobsiteToBrowseList]: async () => {
        // ensure updated candidate data right after TC
        await useCandidateStore().reloadCandidate()
    },
    [AInesToolCallType.RemoveJobsiteFromBrowseList]: async () => {
        // ensure updated candidate data right after TC
        await useCandidateStore().reloadCandidate()

    },
};

export const performToolCall = async (toolCall: KapitelToolCall): Promise<any> => {
    const toolCallFunctionName = toolCall.function as AInesToolCallType;
    const toolCallFunctionArgs: object = JSON.parse(toolCall.arguments);

    // Check if there is a local hook to handle the call
    if (localToolCallHooks[toolCallFunctionName]) {
        console.log(`Using local handling for ${toolCallFunctionName}`);
        return await localToolCallHooks[toolCallFunctionName](toolCallFunctionArgs);
    } else {

        // Perform the main tool call request
        const result = await performToolCallRequest(toolCall);

        // Execute the post-call hook if it exists
        if (postToolCallHooks[toolCallFunctionName]) {
            postToolCallHooks[toolCallFunctionName](toolCallFunctionArgs);
        }

        return result;
    }

};
