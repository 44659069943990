import {Jobsite, TimesheetToDo} from "@/graphql/generated/graphql";
import {defineStore} from "pinia";
import {getJobsiteDescription} from "@/helper/jobsite";
import datetime from "@/helper/datetime/datetime";
import date from '@/helper/datetime/date'
import {kapitelDateString, kapitelDateTimeString} from "@/graphql/kapitelTypes";
import {fetchTimesheetToDos, findByBookingId, saveTimesheet} from "@/helper/timesheet";
import useOfflineHelper from "@/helper/offline";
import {useGlobalEmitter} from "@/helper/emitter";
import {kapitelErrorHandler} from "@/helper/error";

interface JobsiteLookup {
    [key: number]: string;
}

interface TimeSheetState {
    initialized: boolean,
    timesheetToDos: TimesheetToDo[];
    signedTimesheetToDos: TimesheetToDo[];
    timesheetToDosInSignatureProcess: TimesheetToDo[];
    previousTimesheetSignateurNames: JobsiteLookup,
    previousTimesheetSignateurEMails: JobsiteLookup,
}

let ensureInitDone = false
let isSyncing = false;
export const useTimesheetStore = defineStore("timesheet", {
    persist: {
        paths: ['initialized', 'timesheetToDos', 'signedTimesheetToDos', 'previousTimesheetSignateurNames', 'previousTimesheetSignateurEMails']
    },
    state: (): TimeSheetState => ({
        initialized: false, // first fetch done
        timesheetToDos: [],
        signedTimesheetToDos: [],
        timesheetToDosInSignatureProcess: [],
        previousTimesheetSignateurNames: {},
        previousTimesheetSignateurEMails: {},
    }),
    getters: {
        getSignableTimesheetTodos: (state) => {
          return (now: kapitelDateTimeString | undefined = undefined) : TimesheetToDo[] => {
              if (!now) {
                  now = datetime.getNow()
              }

              return state
                  .timesheetToDos
                  .filter((tstd: TimesheetToDo) => {
                      const timesheet = tstd.timesheet;
                      const booking = tstd.booking;

                      // ?
                      if (!timesheet || !booking) {
                          return false
                      }

                      // tstd already signed and pending save: filter
                      if (state.signedTimesheetToDos.find((ststd) => ststd.booking.id == tstd.booking.id)) {
                          return false
                      }

                      // Warning: this logic is reproduced in the back end. if you change here, you probably also should change there
                      if (
                          datetime.isBefore(
                              datetime.addHours(now, 1),
                              timesheet.begin,
                          )
                      ) {
                          return false
                      }
                      return true
                  })
            }
        },
        getSignableTimesheetTodosForJobsiteId(state) {
            return (jobsiteId: number, now: kapitelDateTimeString | undefined = undefined): TimesheetToDo[] => {
                return this.getSignableTimesheetTodos(now).filter((tstd: TimesheetToDo) => {
                    const tstdJobsite = tstd.booking.jobsite;
                    return (tstdJobsite && tstdJobsite.id == jobsiteId)
                })
            }
        },
        getToDosByMonth (state) {
            return (month: kapitelDateString): TimesheetToDo[] => {
                const toDos = this.getSignableTimesheetTodos()
                return toDos.filter(toDo => date.isSameMonth(toDo.booking?.date, month))
            }
        },
        getPreviousTimesheetSignateurNamesLookup: (state) => {
            return state.previousTimesheetSignateurNames
        },
        getPreviousTimesheetSignateurEMailsLookup: (state) => {
            return state.previousTimesheetSignateurEMails
        },
        getToDoJobsites (state) {
            const jobsites: Array<Jobsite> = []
            this.getSignableTimesheetTodos().forEach(tstd => {
                if (tstd.booking?.jobsite) jobsites.push(tstd.booking?.jobsite)
            })
            const uniqueJobsites = jobsites.filter((j, i) => jobsites.findIndex(j2 => j2.id === j.id) === i)
            return uniqueJobsites.map(jobsite => ({
                jobsite,
                description: getJobsiteDescription(jobsite)
            }))
        },
    },
    actions: {
        reset() {
            this.initialized = false // first fetch done
            this.timesheetToDos = []
            this.signedTimesheetToDos = []
            this.timesheetToDosInSignatureProcess = []
        },
        firstFetchDone() {
            return this.initialized
        },
        async ensureInit() {
            if (ensureInitDone) {
                return
            }

            ensureInitDone = true

            const firstFetch = async () => {
                this.timesheetToDos = await fetchTimesheetToDos()
                this.initialized = true // first fetch done
            }

            if (!this.firstFetchDone()) {
                await firstFetch()
            } else {
                // trigger sync in parallel
                this.sync()
            }

            // online again event listener
            useOfflineHelper().onOnline(this.sync)

            // employee switch event listener
            useGlobalEmitter().on("AppStateChanged", ({state, impersonationUserSwitch}) => {
                if (state === 'employee' && impersonationUserSwitch) {
                    this.reset()
                    firstFetch()
                }
            })

        },
        async saveUnsavedSignatures() : Promise<Boolean> {

            // alle in this.signedTimesheetToDos speichern
            // alle gespeicherten aus den this.signedTimesheetToDos entfernen

            const toRemove : TimesheetToDo[] = [];
            for await (const tstd of this.signedTimesheetToDos) {
                try {
                    await saveTimesheet(tstd, true)
                    toRemove.push(tstd)
                } catch (error) {
                    kapitelErrorHandler(error)
                    return false
                }
            }
            toRemove.forEach((tstd: TimesheetToDo) => {
                this.removeSignedAndSavedTimesheetToDo(tstd)
            })
            return true
        },
        async sync() {

            // console.log("syncing attempt")

            if (!this.firstFetchDone()) {
                // don't update when not initialized yet
                return
            }

            if (this.isInSignatureProcess()) {
                // don't update when in signature process
                return
            }
            const offlineHelper = useOfflineHelper()
            if(offlineHelper.isOffline.value){
                return
            }

            if(isSyncing){
                return
            }
            isSyncing = true
            // console.log("actually syncing")

            try {
                if (this.hasUnsavedSignatures()) { // first try to save unsaved
                    const saveSuccess = await this.saveUnsavedSignatures()
                    if (!saveSuccess) {
                        // skip sync
                        isSyncing = false
                        return
                    }
                }

                const result = await fetchTimesheetToDos()
                if(result){
                    this.timesheetToDos = result
                }
            } finally {
                isSyncing = false
            }

            // console.log("syncing done.")

        },
        isInSignatureProcess() {
            return this.timesheetToDosInSignatureProcess.length > 0
        },
        cancelSignatureProcess() {
            this.timesheetToDosInSignatureProcess = []
        },
        startSignatureProcess(jobsiteId: number) {
            this.getSignableTimesheetTodosForJobsiteId(jobsiteId)
                .forEach(
                    (tstd) => this.timesheetToDosInSignatureProcess.push(
                        JSON.parse(JSON.stringify(tstd))
                    )
                )
        },
        async finishSignatureProcess() {
            this.signedTimesheetToDos = this.signedTimesheetToDos.concat(this.timesheetToDosInSignatureProcess)

            this.timesheetToDosInSignatureProcess.forEach((tstd)=>{

                const bookingId = tstd?.booking?.id
                if (bookingId) {
                    const correspondingTstd = findByBookingId(bookingId, this.timesheetToDos)
                    if (correspondingTstd) {
                        this.removeReferenceTimesheetToDo(correspondingTstd)
                    }
                }
            })

            this.timesheetToDosInSignatureProcess = []
            this.sync()
            // await this.saveUnsavedSignatures() // todo
        },
        hasUnsavedSignatures() {
            return this.signedTimesheetToDos.length > 0
        },
        updateTimesheet(timesheetToDo: TimesheetToDo) {
            const bookingId = timesheetToDo?.booking?.id
            if (bookingId) {
                const toUpdate = findByBookingId(bookingId, this.timesheetToDosInSignatureProcess)

                if (toUpdate) {
                    toUpdate.timesheet = timesheetToDo?.timesheet
                } else {
                    throw new Error("TimesheetToDo not found in collection.")
                }
            } else {
                throw new Error("No valid booking id given.")
            }
        },
        removeReferenceTimesheetToDo(timesheetToDo: TimesheetToDo) {
            const index = this.timesheetToDos.indexOf(timesheetToDo);
            if (index > -1) {
                this.timesheetToDos.splice(index, 1);
            }
        },
        removeTimesheetToDoFromSignatureProcess(timesheetToDo: TimesheetToDo) {
            const index = this.timesheetToDosInSignatureProcess.indexOf(timesheetToDo);
            if (index > -1) {
                this.timesheetToDosInSignatureProcess.splice(index, 1);
            }
        },
        removeSignedAndSavedTimesheetToDo(timesheetToDo: TimesheetToDo) {
            const index = this.signedTimesheetToDos.indexOf(timesheetToDo);
            if (index > -1) {
                this.signedTimesheetToDos.splice(index, 1);
            }
        },
        addTimesheetToDoFromSignatureProcess(timesheetToDo: TimesheetToDo) {
            this.timesheetToDosInSignatureProcess.push(timesheetToDo);
        },
        setSignateur(name: string, email: string) {
            let jobsiteId: number | undefined = undefined;
            this.timesheetToDosInSignatureProcess.forEach((tstd) => {
                const timesheet = tstd.timesheet;
                const booking = tstd.booking;
                if (timesheet) {
                    timesheet.signateur = name;
                    timesheet.signateurEmail = email;
                }
                if (booking) {
                    jobsiteId = booking?.jobsite.id
                }
            });
            if (jobsiteId) {
                if (name != "") {
                    this.previousTimesheetSignateurNames[jobsiteId] = name;
                }
                if (email != "") {
                    this.previousTimesheetSignateurEMails[jobsiteId] = email;
                }
            }
        },
        discardSignateur() {
            this.timesheetToDosInSignatureProcess.forEach((tstd) => {
                const timesheet = tstd.timesheet;
                if (timesheet) {
                    timesheet.signateur = undefined;
                    timesheet.signateurEmail = undefined;
                }
            });
        },
        setSignature(signature: string) {
            this.timesheetToDosInSignatureProcess.forEach((tstd) => {
                const timesheet = tstd.timesheet;
                if (timesheet) {
                    timesheet.signature = signature;
                }
            });
        },
        discardSignature() {
            this.timesheetToDosInSignatureProcess.forEach((tstd) => {
                const timesheet = tstd.timesheet;
                if (timesheet) {
                    timesheet.signature = undefined;
                }
            });
        },
    },
});
