import {Capacitor} from "@capacitor/core";

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
    }
}

import { Mute } from '@capgo/capacitor-mute';
import {kapitelErrorHandler} from "@/helper/error";

const init = () => {
    //@ts-nocheck
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Ensure the AudioContext is resumed on user interaction (required on iOS)
    const resumeAudioContext = () => {
        if (audioContext.state === 'suspended') {
            audioContext.resume();
        }
    }
    window.addEventListener('touchend', resumeAudioContext, false);
    window.addEventListener('click', resumeAudioContext, false);

    return audioContext
}

const audioContext = init()
let currentSource: AudioBufferSourceNode | null = null; // Store current source reference

export const audioContextPlay = async (buffer: ArrayBuffer, cb: () => void) => {
    try {
        if(Capacitor.isNativePlatform()){
            const isMuted = await Mute.isMuted()
            if(isMuted.value){
                kapitelErrorHandler("Gerät ist stummgeschaltet, du kannst mich vermutlich nicht hören.")
            }
        }
        const decodedData = await audioContext.decodeAudioData(buffer);
        const source = audioContext.createBufferSource();
        source.buffer = decodedData;
        source.connect(audioContext.destination);

        source.onended = () => {
            cb();
            currentSource = null; // Clear reference after playback ends
        };

        source.start();
        currentSource = source; // Store current source reference
    } catch (error) {
        console.error("Error decoding audio data", error);
        cb()
    }
};

// Function to stop playback
export const stopAudio = () => {
    if (currentSource) {
        currentSource.stop();
        currentSource = null; // Clear the reference after stopping
    }
};
