<template>
    <div
        class="calendar-list-item"
        @click="onDayClick"
        data-cy="Calendar-Day"
        v-bind:data-cy-date="kapitelDate"
    >
        <div
            class="date"
            :class="{'today': dateHelper.isToday(kapitelDate), 'weekend': dateHelper.isWeekend(kapitelDate) }"
        >
            <span>
                {{ dateHelper.getDayOfMonthFromKapitelDate(kapitelDate) }}
            </span>
            <span>
                {{ dateHelper.formatWeekdayShortFromKapitelDate(kapitelDate) }}
            </span>
        </div>
        <div
            class="body"
        >
            <Skeleton :items="['block--height-5.0rem']" v-if="propsLoading"></Skeleton>
            <template v-else>
            <div class="shifts" v-if="dayItemType !== 'empty'">
                <span
                    v-for="shift in orderedShiftTypes"
                    :key="shift"
                    :class="{'booked': shiftStates[shift].isBooked, 'absence': shiftStates[shift].isAbsent, 'available': shiftStates[shift].isAvailable, 'suggested': shiftStates[shift].isSuggested, 'wishDemands': shiftStates[shift].isWish}"
                >
                    {{ shift }}
                </span>
            </div>
            <div
                class="content"
                :class="{'available': dayItemType == 'availability', 'absence': dayItemType == 'absence', 'booked': !!props.booking, 'wishDemands': dayItemType == 'wishDemands'}"
            >
                <div
                    v-if="booking"
                    class="booking"
                >
                    <div
                        class="labels"
                    >
                        <p
                            class="text-bold"
                        >
                            {{ booking.labelJobsite }}
                        </p>
                        <p>
                            {{ booking.begin }} - {{ booking.until }} Uhr
                        </p>
                    </div>
                    <div
                        class="image"
                    >
                        <Avatar
                            :src="booking.image"
                        />
                    </div>
                </div>
                <p
                    v-else-if="props.absence"
                >
                    {{ getLabelForAbsenceType(absence.type) }}<br>
                    {{ absence.comment }}
                </p>
                <p
                    v-else-if="props.availability && props.availability?.shifts.length>0"
                >
                    {{ _availabilityEffectiveTimesHumanReadable }}
                </p>
                <p
                    v-else-if="props.demandSuggestions?.length>0"
                >
                    Passende Dienste auf {{ props.demandSuggestions.length > 1 ? props.demandSuggestions.length + " Stationen" : ""+props.demandSuggestions[0].jobsite?.name}}
                </p>
                <p
                    v-else-if="props.wishDemands?.length>0"
                >
                    Wunschdienste auf {{ props.wishDemands.length > 1 ? props.wishDemands.length + " Stationen" : ""+props.wishDemands[0].jobsite?.name}}
                </p>
            </div>

                <ion-action-sheet
                    :is-open="isWishDemandsModalOpen"
                    :buttons="actionSheetWishDemandButtons"
                    @didDismiss="onActionModalWishDemandDismiss"
                ></ion-action-sheet>

                <ion-action-sheet
                    :is-open="isConfirmRemoveWishDemandModalOpen"
                    :buttons="actionSheetConfirmWishDemandButtons"
                    @didDismiss="onActionModalConfirmWishDemandDismiss"
                ></ion-action-sheet>

            <Modal
                ref="suggestionsModal"
                v-model="isSuggestionModalOpen"
                title="Verfügbarkeitsvorschläge"
            >
                <h3>{{dateHelper.formatKapitelDate(kapitelDate, 'DD')}}</h3>
                <span v-if="props.demandSuggestions.length == 1">
                Es gibt einen passenden Dienst im {{demandSuggestions[0].shiftType}} auf {{getShortJobsiteDescription(demandSuggestions[0].jobsite)}}.<br><br>
                Wenn du möchtest, füge direkt eine passende Verfügbarkeit hinzu oder trage ein Wunschfrei ein um die Information auszublenden.
                </span>
                <span v-else-if="props.demandSuggestions.length > 1">
                Es gibt {{ props.demandSuggestions.length }} passende Dienste:
                <ul >
                    <li v-for="demand in props.demandSuggestions" :key="demand">
                        {{demand.shiftType}} auf {{getShortJobsiteDescription(demand.jobsite)}}
                    </li>
                </ul>
                <br>
                Wenn du möchtest, füge direkt eine passende Verfügbarkeit hinzu oder trage ein Wunschfrei ein um die Information auszublenden.
                </span>


                <br><br>
                <ion-button
                    @click="()=>{isSuggestionModalOpen=false; isWishDemandsModalOpen=false; isConfirmRemoveWishDemandModalOpen=false; toDeleteWishDemand=undefined; onDayClick('detail')}"
                    expand="block"
                    color="light"
                >
                    Verfügbarkeit bearbeiten
                </ion-button>
                <ion-button
                    @click="addAbsence"
                    expand="block"
                    color="light"
                >
                    Wunschfrei eintragen
                </ion-button>
                <ion-button
                    @click="addAvailabilities"
                    expand="block"
                    color="primary"
                >
                    {{ uniqueShifts(props.demandSuggestions.map(d=>d.shiftType)).toSorted(sortShiftTypes).join(", ")  }} verfügbar stellen

                </ion-button>
            </Modal>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router'
import {availabilityShiftsAndTimeConstraintsToIntervals, generateAvailability, generatePropertiesFromDefault, hasShifts} from "@/helper/availability";
import {computed, Ref, ref, watch} from 'vue'
import { generateAbsence, getLabelForAbsenceType } from '@/helper/absence'
import Avatar from '@/components/Avatar.vue';
import {orderedShiftTypes, sortShiftTypes, uniqueShifts} from "@/helper/shiftTypes";
import datetime from "@/helper/datetime/datetime"
import Skeleton from "@/components/Skeleton.vue";
import dateHelper from "@/helper/datetime/date";
import { getImageForJobsite } from '@/helper/jobsite';
import { availabilityEffectiveTimesHumanReadable } from "@/helper/availability";
import Modal from '@/components/Modal.vue'
import { AbsenceType, AvailabilityShift, Demand, ShiftType } from '@/graphql/generated/graphql';
import {IonActionSheet, IonButton} from '@ionic/vue'
import { getShortJobsiteDescription } from "@/helper/jobsite"
import {useAuthStore} from "@/store/auth";
import {removeWishDemand} from "@/helper/wishDemand";

const router = useRouter();

const emit = defineEmits(['set:availability', 'set:absence', 'click:date'])

const props = defineProps({
    calendarDate: undefined,
    calendarDateSingleton: undefined,
    availability: undefined,
    booking: undefined,
    absence: undefined,
    demandSuggestions: {
        type: Array,
        required: false,
        default: []
    },
    wishDemands: {
        type: Array,
        required: false,
        default: []
    },
    availabilityShiftDefaults: undefined,
    availabilityPlanningThreshold: undefined,
    propsLoading: {
        type: Boolean,
        default: false
    }
})

const kapitelDate = computed(() => props.calendarDate?.id || props.calendarDateSingleton.date )

const dayItemType = computed(()=>{

    if(props.booking){
        return "booking"
    }else if(props.absence){
        return "absence"
    }else if (props.availability &&  hasShifts(props.availability)){
        return "availability"
    } else if(props.demandSuggestions.length>0) {
        return "demandSuggestions"
    } else if(props.wishDemands.length>0){
        return "wishDemands"
    }
    return "empty"
})

const shiftStates = computed(() => {
    const value = orderedShiftTypes.reduce((acc, shift) => {
        const isBooked = props.booking?.shiftType === shift
        const isAbsent = !!props.absence
        const isAvailable = !isBooked && !isAbsent && props.availability?.shifts.some(sh => sh.type === shift)
        const isSuggested = !isBooked && !isAbsent && !isAvailable && props.demandSuggestions.some((d)=>d.shiftType === shift)
        const isWish = !isBooked && !isAbsent && !isAvailable && !isSuggested && props.wishDemands.some((d)=>d.shiftType === shift)

        acc[shift] = {
            isBooked: isBooked,
            isAbsent: isAbsent,
            isAvailable: isAvailable,
            isSuggested: isSuggested,
            isWish: isWish
        }

        return acc
    }, {})

    return value
})

const suggestionsModal = ref(undefined)

const isSuggestionModalOpen = ref(false)
const isWishDemandsModalOpen = ref(false)
const isConfirmRemoveWishDemandModalOpen = ref(false)
const toDeleteWishDemand: Ref<Demand|undefined> = ref(undefined)

watch(() => props.modelValue, value => {
    isSuggestionModalOpen.value = value
    isWishDemandsModalOpen.value = value
    isConfirmRemoveWishDemandModalOpen.value = value
})

// watch(() => isSuggestionModalOpen.value, value => {
//     emit('update:modelValue', value)
// })


const _availabilityEffectiveTimesHumanReadable = computed(() => {
    return availabilityEffectiveTimesHumanReadable(props.availability)
})

const booking = computed(() => {
    if (!props.booking) {
        return
    }

    return {
        labelJobsite: props.booking.jobsite.client.abbreviation + ' - ' + props.booking.jobsite.abbreviation,
        begin: datetime.formatKapitelDateTime(props.booking.begin, datetime.kapitelDateTimeFormatTime),
        until: datetime.formatKapitelDateTime(props.booking.until, datetime.kapitelDateTimeFormatTime),
        image: getImageForJobsite(props.booking.jobsite),
    }
})



const addAvailabilities = () => {

    suggestionsModal?.value?.dismiss().then(()=>{
        const availability = generateAvailability(kapitelDate.value)

        orderedShiftTypes.forEach((shiftType) => {
            if(props.demandSuggestions.filter((d:Demand) => d.shiftType === shiftType ).length>0){
                const shiftDefault = props.availabilityShiftDefaults.find(shiftDef => shiftDef.type === shiftType)
                availability.shifts.push(generatePropertiesFromDefault(kapitelDate.value, shiftDefault))
            }
        })
        emit("set:availability", availability)
    })
}

const addAbsence = () => {
    suggestionsModal?.value?.dismiss().then(()=>{
        const absence =  generateAbsence(kapitelDate.value, AbsenceType.Timeoff)
        emit("set:absence", absence)
    })
}

const onDayClick = (detailOrSuggestion:string) => {
    const wish = props.wishDemands.length>0 && dayItemType.value == "wishDemands"

    const suggestion = !(
        props.booking
        || (props.availability && hasShifts(props.availability))
        || props.absence
        || props.demandSuggestions.length==0
    )


    if(useAuthStore().isCandidate()){
        if(wish) {
            isWishDemandsModalOpen.value = true
        }
    } else {
        if(suggestion){
            isSuggestionModalOpen.value = true
        }else {
            emit('click:date', { dateId: kapitelDate.value, expectedContent: dayItemType.value })
        }
    }

}

const actionSheetWishDemandButtons = (computed(() => {

    const wishDemands = [];

    props.wishDemands.forEach((demand) => {
        wishDemands.push({
            text: demand.shiftType + " auf " + demand.jobsite.name + " entfernen",
            data: {
                action: "removeWishDemand",
                demand: demand
            },
        });
    });

    return [].concat(wishDemands, [
        {
            text: "Abbrechen",
            role: "cancel",
            data: {
                action: "cancel",
            },
        }
    ])
}));

const actionSheetConfirmWishDemandButtons = [
    {
        text: "Ja, Wunschdienst entfernen",
        role: 'destructive',
        data: {
            action: "removeWishDemand",
        },
    },
    {
        text: "Abbrechen",
        role: "cancel",
        data: {
            action: "cancel",
        },
    },
];

const onActionModalConfirmWishDemandDismiss = async (event) => {
    const data = event.detail.data;

    if(!data?.action){
        isConfirmRemoveWishDemandModalOpen.value = false
        toDeleteWishDemand.value = undefined
        return
    }

    switch (data.action) {
        case "removeWishDemand":
            removeWishDemand(toDeleteWishDemand.value)
            break;
    }

    isConfirmRemoveWishDemandModalOpen.value = false
    toDeleteWishDemand.value = undefined
};

const onActionModalWishDemandDismiss = async (event) => {
    const data = event.detail.data;

    if(!data?.action){
        isWishDemandsModalOpen.value = false
        return
    }

    switch (data.action) {
        case "removeWishDemand":
            toDeleteWishDemand.value = data.demand
            isConfirmRemoveWishDemandModalOpen.value = true
            break;
    }

    isWishDemandsModalOpen.value = false
};


</script>

<style lang="scss">
.calendar-list-item {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: var(--custom-spacing-app-content-padding-horizontal);

    min-height: 5.5rem; // reducing jumpiness with skeleton loader

    > .date {
        flex: 0 0 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > span:first-child {
            font-size: var(--custom-font-size-large);
            color: var(--ion-color-dark);
            margin-bottom: 0.15em;
        }

        > span:last-child {
            font-size: var(--custom-font-size-small);
            color: var(--ion-color-medium);
        }

        &.today {
            > span:first-child {
                color: var(--ion-color-primary);
            }
        }

        &.weekend {
            > span:last-child {
                color: var(--ion-color-primary);
            }
        }
    }

    > .body {
        display: flex;
        flex: 1 1 100%;

        > .shifts {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            display: flex;
            flex-direction: column;

            > span {
                color: var(--ion-color-grey-tint-1);
                font-size: var(--custom-font-size-extrasmall);
                font-weight: var(--custom-font-weight-bold);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.2em;
                height: 2.2em;
                border-radius: 50%;
                margin-bottom: 0.2em;

                &.available {
                    background-color: var(--ion-color-light);
                    color: var(--ion-color-dark);
                }

                &.wishDemands {
                    background-color: var(--ion-color-light);
                    color: var(--ion-color-dark);
                }

                &.suggested {
                    /* background-color: var(--ion-color-light); */
                    color: var(--ion-color-dark);
                }


                &.booked {
                    background-color: var(--ion-color-dark);
                    color: var(--ion-color-white);
                }
            }
        }

        > .content {
            flex: 1 1 100%;
            border-radius: 1rem;
            padding: 1rem;
            display: flex;
            align-items: center;

            &,
            * {
                font-size: var(--custom-font-size-regular);
            }

            &.available {
                background-color: var(--ion-color-light);
            }

            &.absence {
                background-color: rgba(var(--ion-color-danger-rgb), 0.1);
                color: var(--ion-color-danger);
            }

            &.booked {
                background-color: var(--ion-color-dark);
                color: var(--ion-color-white);

                > .booking {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    > .labels {
                        flex: 1 1 100%;
                        margin-right: 1rem;
                    }

                    > .image {
                        flex: 0 0 auto;
                    }
                }
            }

            &.wishDemands {
                background-color: var(--ion-color-light);
            }
        }
    }
}
</style>
