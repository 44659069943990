<template>    
    <IonPage>
        <IonContent fullscreen padding="true">

            <!-- RICH RESPONSES -->

            <TimesheetList
                v-if="false"
                :init-data="1"
            />
            <JobsiteList
                v-if="false"
                :init-data="AInesJobsiteListType.Favorite"
            />
                <!--{listType:, selectable: true}-->
            <PlanningStatus
                v-if="false"
                init-data="2024-12-01"
            />
            <PlanningStatus
                v-if="false"
                init-data="2025-01-01"
            />
            <MonthCalendar
                v-if="false"
                init-data="2024-09-01"
            />
            <WeekCalendar
                v-if="false"
                init-data="2024-10-01"
            />
            <DayCalendar
                v-if="false"
                init-data="2024-09-26"
            />
            <RegisterBookingSummary
                v-if="false"
                :init-data="{jobsite:'Station 3', shift: ShiftType.Fd, dates: ['2024-10-01']}"
            />
            <JobsiteSearch
                v-if="false"
                init-data="WMK-S4"
            />

            // @TWINKI
            <InitCandidatePromotion v-if="true" :useLastChatMessageAsIntroMessage="false" :open-modal-immediately="false"/>

            // @TWINKI
            <CandidateMissingJobsiteType v-if="true" :init-data="'1:1 Betreuung'"/>


            <!-- ?  -->




            <!-- DETAIL MODALS  -->

            <Modal v-if=false v-model="showModal" title="Detail" class="no-padding-left no-padding-right">
                <AvailabilityAbsenceEditor
                    v-if="false"
                    :availability="undefined"
                    :absence="undefined"
                    :date="undefined"
                ></AvailabilityAbsenceEditor>
                <CalendarDetail
                    v-if="false"
                    :date-id="undefined"
                ></CalendarDetail>
                <RegisterBookingSummary
                    v-if="false"
                    :init-data="{jobsiteSearchString:'Station 3', dates:['2024-11-10'], shift:ShiftType.Fd}"
                ></RegisterBookingSummary>

            </Modal>


            <!-- PROCESS MODALS  -->


<!--            <Modal v-if=false v-model="showModal" title="Prozess">-->
<!--                <RegisterBookingTopSheet-->
<!--                    v-if="false"-->
<!--                    :init-data="undefined"-->
<!--                ></RegisterBookingTopSheet>-->
<!--            </Modal>-->

            <StartPlanningMonth
                v-if="false"
                :month="'2024-12-01'"
                v-model="showModal"
            />

            <CalendarModal
                v-if="false"
                v-model="showModal"
                initial-date="2024-09-01"
            ></CalendarModal>

            <JobsiteDetails
                v-if="false"
                v-model="jobsiteId"
            ></JobsiteDetails>

            <JobsiteInfo
                v-if="true"
                :init-data="jobsiteId.toString()"
                :render-shifts="true"
                expandable
                />

            <JobsiteInfo
                v-if="true"
                :init-data="jobsiteId.toString()"
                />

        </IonContent>
    </IonPage>
</template>

<script setup lang="ts">
import {IonContent, IonPage} from '@ionic/vue';
import RegisterBookingSummary from "@/views/Chat/RichResponses/RegisterBookingSummary.vue";
import {AInesJobsiteListType, ShiftType} from "@/graphql/generated/graphql";
import JobsiteList from "@/views/Chat/RichResponses/JobsiteList.vue";
import PlanningStatus from "@/views/Chat/RichResponses/PlanningStatus.vue";
import WeekCalendar from "@/views/Chat/RichResponses/WeekCalendar.vue";
import DayCalendar from "@/views/Chat/RichResponses/DayCalendar.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import Modal from "@/components/Modal.vue";
import {Ref, ref} from "vue";
import JobsiteSearch from "@/views/Chat/RichResponses/JobsiteSearch.vue";
import TimesheetList from "@/views/Chat/RichResponses/TimesheetList.vue";
import JobsiteDetails from "@/views/JobsiteDetails.vue";
import AvailabilityAbsenceEditor from "@/components/AvailabilityAbsenceEditor.vue";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";
import InitCandidatePromotion from "@/views/Chat/RichResponses/InitCandidatePromotion.vue";
import JobsiteInfo from './Chat/RichResponses/JobsiteInfo.vue';
import CandidateMissingJobsiteType from "@/views/Chat/RichResponses/CandidateMissingJobsiteType.vue";


const showModal: Ref<boolean> = ref(false);
const jobsiteId = ref(6)
</script>

<style lang="scss" >
</style>
