<script setup lang="ts">
import {IonButton, IonContent, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue";
import {computed, defineExpose, PropType, Ref, ref, watch} from "vue";
import Modal from '@/components/Modal.vue';
import {DataLoader} from "@/helper/dataLoader";
import {useCandidateStore} from "@/store/candidate";
import {Candidate} from "@/graphql/generated/graphql";
import AInesMessage from "@/views/Chat/AInesMessage.vue";

const emit = defineEmits(['submit']);

const modelValue = defineModel({type: Boolean});
const isOpen = ref(modelValue.value);
const modal = ref();

watch(() => modelValue.value, value => isOpen.value = value);

watch(() => isOpen.value, value => {
    modelValue.value = value;
});

const props = defineProps({
    missingJobsiteType: {
        type: String as PropType<string>,
        required: false,
        default: ''
    },
})

const introMessage = 'Das von dir gewünschten Stationen sind aktuell noch nicht dabei? Kein Problem. Wir benachrichtigen dich gerne, wenn sich das ändert.'

// local models
const fullName = ref('');
const phoneNumber = ref('');
const interestedInMissingJobsiteType = ref('');

// Get the candidate entity
const candidate : Ref<Candidate|undefined> = ref(undefined);
const dateLoader = new DataLoader(async _ => candidate.value = await useCandidateStore().ensureCandidate())

const init = async () => {
    await dateLoader.load()

    // Initialize form fields with candidate data
    fullName.value = candidate.value?.name || ''
    phoneNumber.value = candidate.value?.contact || ''
    interestedInMissingJobsiteType.value = props.missingJobsiteType || candidate.value?.interestedInMissingJobsiteType || ''
}

const formIsValid = computed(() => {
    return fullName.value.trim() !== '' &&
        phoneNumber.value.trim() !== '' &&
        interestedInMissingJobsiteType.value !== '';
});

const onCancel = () => {
    isOpen.value = false;
    resetForm();
};

const onSubmit = () => {
    emit('submit', {
        fullName: fullName.value,
        phoneNumber: phoneNumber.value,
        interestedInMissingJobsiteType: interestedInMissingJobsiteType.value
    });
    isOpen.value = false;
    resetForm();
};

const resetForm = () => {
    fullName.value = '';
    phoneNumber.value = '';
    interestedInMissingJobsiteType.value = '';
};

defineExpose({
    init
});
init()
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Fehlende Stationskategorie"
        class="no-padding-left no-padding-right"
        ref="modal"
        fullscreen
        :toolbar="true"
    >
        <template v-slot:default>
            <ion-content>
                <AInesMessage :text-content="introMessage" />

                <ion-list class="boxed no-padding-top">
                    <!-- Input field for Full Name -->
                    <ion-item>
                        <ion-label position="stacked">Vollständiger Name</ion-label>
                        <ion-input v-model="fullName" placeholder="Geben Sie Ihren vollständigen Namen ein"></ion-input>
                    </ion-item>
                    <!-- Input field for Phone Number -->
                    <ion-item>
                        <ion-label position="stacked">Telefonnummer</ion-label>
                        <ion-input v-model="phoneNumber" type="tel" placeholder="Geben Sie Ihre Telefonnummer ein"></ion-input>
                    </ion-item>
                    <!-- Input field for missing jobsiteType -->
                    <ion-item>
                        <ion-label position="stacked">Stationskategorie</ion-label>
                        <ion-input v-model="interestedInMissingJobsiteType" placeholder="Geben Sie Ihr Interessensgebiet ein"></ion-input>
                    </ion-item>

                </ion-list>
            </ion-content>
        </template>
        <template v-slot:actions>
            <!-- Submit and Cancel Buttons -->
            <ion-button @click="onCancel" expand="block" color="secondary" fill="clear">Abbrechen</ion-button>
            <ion-button @click="onSubmit" expand="block" color="primary" :disabled="!formIsValid">Absenden</ion-button>
        </template>
    </Modal>
</template>

<style lang="scss">
.qualification-segment {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
    margin-bottom: 8px;
}
.jobsite-selector-modal-scroller {
    height: 50vh;
    overflow: auto;
}

.jobsite-selector-searchbar {
    padding-left: 0;
    padding-right: 0;
}

.jobsite-selector-actions {
    padding-top: 1rem;
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
}

.alreadySelected {
    opacity: 0.75;
}
</style>
