import {useAuthStore} from "@/store/auth";
import {useCandidateStore} from "@/store/candidate";
import date from "@/helper/datetime/date";
import datetime from "@/helper/datetime/datetime";

export const generateAdditionalInstructions = async () => {
    let result: string = ""

    if (useAuthStore().isCandidate()) {

        const candidateStore = useCandidateStore()
        const candidate = await candidateStore.ensureCandidate()

        if (candidate.employeeQualification?.qualification) {
            result = result + '\n\nDer Nutzer hat die Qualifikation ' + candidate.employeeQualification?.qualification

            if (candidate.employeeQualification.specialisation) {
                result = result + '\nMit der Spezialisierung ' + candidate.employeeQualification?.specialisation
            }

            if (candidate.employeeQualification.experiences) {
                result = result + '\nMit den Erfahrungen ' + JSON.stringify(candidate.employeeQualification?.experiences)
            }

        } else {
            result = result + '\n\nDer Nutzer hat den Bewerbungsprozess noch nicht begonnen.'
        }

        if (candidateStore.isPromoted) {
            result = result + '\n\nDer Nutzer hat bereits den Bewerbungsprozess begonnen.'
        } else {
            result = result + '\n\nDer Nutzer hat den Bewerbungsprozess noch nicht begonnen.'
        }


        result = result + "\n\nDie gemerkten Stationen des Nutzers sind:"
        candidate.favoriteJobsites.forEach((jobsite) => {
            result = result + '* ' + JSON.stringify(jobsite)
        })


    }

    // current clock

    result = result + "\n\nHeute ist das folgende Datum: " +
        date.getToday()
        + ".\n\nDie aktuelle Uhrzeit ist " + datetime.formatKapitelDateTime(
            datetime.getNow(),
            datetime.kapitelDateTimeFormatTime
        ) + "."

    return result
}
