<template>
    <Modal :title='dateId?date.formatKapitelDate(dateId, "d. MMMM yyyy"):"Tagesdetails"' v-model="isOpen">
            <template v-if="loader.pending.value">
                <!-- Booking Skeleton -->
                <Skeleton
                    v-if="expectedContent === 'booking'"
                    :items="['block--height-10rem', 'margin', 'title', 'margin', 'block--height-10rem']"
                ></Skeleton>

                <!-- Availability Skeleton -->
                <Skeleton
                    v-else
                    :items="['title', 'block--height-4em', 'block--height-4em', 'block--height-4em']"
                ></Skeleton>
            </template>
            <template v-else>
                <!-- Booking Details -->
                <BookingDetails
                    v-if="booking"
                    :booking="booking"
                />

                <!-- Absence Editor -->
                <!-- Uncomment if needed
                <AbsenceEditor
                    v-else-if="absence"
                    :date="calendarDate.id"
                    :absence="absence"
                    @update="loader.reload"
                /> -->

                <!-- Availability or Absence Editor -->
                <template v-else>
                    <AvailabilityAbsenceEditor
                        :availability="availability"
                        :absence="absence"
                        :date="dateId"
                        @set:availability="onSetAvailability"
                        @set:absence="onSetAbsence"
                    />
                    <!-- Uncomment if needed
                    <AbsenceEditor
                        :date="calendarDate.id"
                        :absence="undefined"
                        @update="loader.reload"
                    /> -->
                </template>
            </template>
    </Modal>
</template>

<script setup lang="ts">
import {ref, computed, defineProps, defineExpose, Ref, watch, PropType} from 'vue';
import {IonContent} from '@ionic/vue'
import { generateCalendarDateFromKapitelDateString } from '@/helper/calendar/calendarDate';
import { generateAvailability } from '@/helper/availability';
import { fetchDayForDate } from '@/helper/day';
import BookingDetails from '@/views/CalendarDetail/components/BookingDetails.vue';
import AvailabilityAbsenceEditor from '@/components/AvailabilityAbsenceEditor.vue';
import { DataLoader } from '@/helper/dataLoader';
import Skeleton from '@/components/Skeleton.vue';
import Modal from "@/components/Modal.vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import date from "@/helper/datetime/date";
import DayVue from "@/views/Calendar/Day.vue";
import {Absence, Availability} from "@/graphql/generated/graphql";

const emit = defineEmits(['set:availability', 'set:absence'])

const onSetAvailability = (availability:Availability) => {
    emit("set:availability", availability, false)
}
const onSetAbsence = (absence:Absence) => {
    emit("set:absence", absence, false)
}
export type ExpectedContent = "booking" | "absence" | "availability" | "demandSuggestions" | "empty" | undefined

const props = defineProps({
    expectedContent: {
        type: String as PropType<ExpectedContent>,
        required: false,
        default: undefined
    },
});


const dateId: Ref<kapitelDateString|undefined> = defineModel({type: String as PropType<kapitelDateString>})
const isOpen : Ref<Boolean> = ref(!!dateId.value)
watch(()=>dateId.value, value=>
{

    isOpen.value=!!value
    if(isOpen.value){
        if(availability.value || booking.value || absence.value){
            loader.reload()
        }else{
            loader.load()
        }
    }
})
watch(()=>isOpen.value, value=>{
    if(!value){
        dateId.value = undefined
    }
} )

const availability = ref();
const booking = ref();
const absence = ref();

const kapitelDate = computed(() => {
    if(dateId.value){
        const calendarDate = generateCalendarDateFromKapitelDateString(dateId.value);
        return calendarDate.id
    }
    return undefined
});

const loader = new DataLoader([
    async () => {
        if(!kapitelDate.value){
            return
        }
        const day = await fetchDayForDate(
            kapitelDate.value,
            'availability {effectiveJobsites {id}, differsFromTemplate}'
        );
        availability.value = day.availability || generateAvailability(kapitelDate.value);
        absence.value = day.absence || undefined;
        booking.value = day.booking || undefined;
    }
]);


if (isOpen.value) {
    loader.load()
}

const reload = () => {
    loader.load();
};

defineExpose({
    reload
});
</script>
