<script setup lang="ts">
import TimesheetBookings from "@/views/Timesheet/pages/TimesheetBookings.vue";
import {IonNav} from "@ionic/vue";
import {computed, markRaw, Ref, ref, watch} from "vue";
import TimesheetSignateur from "@/views/Timesheet/pages/TimesheetSignateur.vue";
import TimesheetSignature from "@/views/Timesheet/pages/TimesheetSignature.vue";
import TimesheetJobsites from "@/views/Timesheet/pages/TimesheetJobsites.vue";
import TimesheetDone from "@/views/Timesheet/pages/TimesheetDone.vue";
import {useTimesheetStore} from "@/store/timesheet";
import Modal from "@/components/Modal.vue";
import {Absence} from "@/graphql/generated/graphql";

/**
 * properties
 */

const timesheetStore = useTimesheetStore()

const emit = defineEmits(["done"])

const modal = ref()

const props = defineProps({
    selectedJobsiteId: {
        type: Number,
        required: false,
        default: undefined
    }
})

const selectedJobsiteId: Ref<number|undefined> = ref(props.selectedJobsiteId)
const processStarted: Ref<boolean|undefined> = defineModel()
const isOpen = ref()
watch(()=>processStarted.value, value=>isOpen.value=value)

watch(()=>isOpen.value, value=>{
    if(!value){
        processStarted.value = false
    }
})

watch(()=>props.selectedJobsiteId, value =>selectedJobsiteId.value=value )


const timesheetToDoJobsites = computed(()=> timesheetStore.getToDoJobsites)

const pages : Ref<Array<any>> = computed(() => {
    const pages = [
        markRaw(TimesheetBookings),
        markRaw(TimesheetSignateur),
        markRaw(TimesheetSignature),
        markRaw(TimesheetDone)
    ];

    // if no jobsite id is defined add jobsite selection as first step
    if (!selectedJobsiteId.value) {
        pages.unshift(markRaw(TimesheetJobsites))
    }

    return pages
})


const ionNavigator = () :HTMLIonNavElement => {
    const nav = document.querySelector('ion-nav');
    if (!nav) {
        throw new Error("missing nav")
    }
    return nav
}

const currentPageIndex = ref(0)


const timesheetNavigator = {
    getCurrentPageIndex: () => currentPageIndex.value,
    getPageLength: () => pages.value.length,
    cancel: () => {
        timesheetStore.cancelSignatureProcess()
        modal.value.dismiss()
    },
    next: () => {
        const ionNav = ionNavigator()
        currentPageIndex.value++
        ionNav.push(pages.value[currentPageIndex.value], {timesheetNavigator})
    },
    prev: () => {
        const ionNav = ionNavigator()
        currentPageIndex.value--
        ionNav.push(pages.value[currentPageIndex.value], {timesheetNavigator})
    },
    done: () => {
        modal.value.dismiss().then(()=>{
            emit("done")
        })
        // emit("update:modelValue", undefined)
    }
}

/**
 * methods
 */

const init = async () => {
    if (timesheetStore.isInSignatureProcess()) {
        timesheetStore.cancelSignatureProcess()
    }

    // if no jobsite id was given check how many options we actually have
    if (!selectedJobsiteId.value) {
        if (timesheetToDoJobsites.value.length === 1) {
            selectedJobsiteId.value = timesheetToDoJobsites.value[0].jobsite.id
        }
    }

    if (selectedJobsiteId.value) {
        timesheetStore.startSignatureProcess(selectedJobsiteId.value)
    }

    currentPageIndex.value = 0


    const ionNav = ionNavigator()

    if (await ionNav.canGoBack()) {
        await ionNav.popToRoot({animated: false})
    }
}

const dismiss = () => {
    timesheetStore.cancelSignatureProcess()
}

/**
 * events
 */



const navChanged = async () => {
    const view = await ionNavigator()?.getActive()
    currentPageIndex.value = pages.value.findIndex(component => view?.component === component)
}

</script>

<template>
    <Modal
        ref="modal"
        v-model="isOpen"
        :fullscreen="true"
        :toolbar="true"
        v-on:view:did-present="init"
        class="no-padding-left no-padding-right"
        :title="`Zeiterfassung ${timesheetNavigator.getCurrentPageIndex()+1}/${timesheetNavigator.getPageLength()}`"
        @view:did-dismiss="dismiss"
        stacking
    >
        <ion-nav @ionNavDidChange="navChanged" :root="pages[0]" :rootParams="{timesheetNavigator}"></ion-nav>
    </Modal>
</template>
