<script setup lang="ts">
import { ref } from "vue";
import Modal from "@/components/Modal.vue";
import { IonButton, IonButtons, IonIcon } from "@ionic/vue";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import { trashOutline, notificationsOutline, notificationsOffOutline } from "ionicons/icons";

const isOpen = defineModel({type: Boolean})
const weeklyNotificationsActive = ref(false)

const subs = ref([
    {
        id: 1,
        label: 'Station 1',
        image: '/images/jobsite/1.jpg',
        notifications: true
    },
    {
        id: 2,
        label: 'Station 2',
        image: '/images/jobsite/2.jpg',
        notifications: false
    }
])

const toggleNotificationsForSub = (sub) => {
    // IMPLEMENT
    sub.notifications = !sub.notifications
}

const removeSub = (sub) => {
    // IMPLEMENT
}
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Abos"
        >

        <!-- GENERAL -->

        <h3>Allgemein</h3>

        <RichResponseListItem
            title="Wöchentliche  Benachrichtigungen"
            iconType="icon"
            icon="/icons/calendar-blank.svg"
            >
            <template v-slot:end>
                <ion-buttons>
                    <ion-button
                        color="secondary"
                        fill="clear"
                        @click="weeklyNotificationsActive = !weeklyNotificationsActive"
                        >
                        <ion-icon
                            slot="icon-only"
                            :src="weeklyNotificationsActive ? notificationsOutline : notificationsOffOutline"
                            />
                    </ion-button>
                </ion-buttons>
            </template>
        </RichResponseListItem>

        <!-- JOBSITES -->

        <h3 v-if="subs.length">Einsatzorte</h3>

        <RichResponseListItem
            v-for="sub in subs"
            :title="sub.label"
            iconType="image"
            :icon="sub.image"
            >
            <template v-slot:end>
                <ion-buttons>
                    <ion-button
                        color="secondary"
                        fill="clear"
                        @click="toggleNotificationsForSub(sub)"
                        >
                        <ion-icon
                            slot="icon-only"
                            :src="sub.notifications ? notificationsOutline : notificationsOffOutline"
                            />
                    </ion-button>
                    <ion-button
                        color="secondary"
                        fill="clear"
                        @click="removeSub(sub)"
                        >
                        <ion-icon
                            slot="icon-only"
                            :src="trashOutline"
                            />
                    </ion-button>
                </ion-buttons>
            </template>
        </RichResponseListItem>

        <template #actions="{dismiss}">
            <ion-button
                color="primary"
                expand="block"
                @click="dismiss"
                >
                Ok
            </ion-button>
        </template>
    </Modal>
</template>
