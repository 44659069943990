<template>
    <!-- Image -->

    <img
        class="teaser-image"
        :src="getImageForJobsite(props.booking.jobsite)"
    />

    <!-- Shift -->

    <ion-card
        class="custom-ion-card-dark"
    >
        <ion-card-header>
            <ion-card-title>
                Einsatz
            </ion-card-title>
        </ion-card-header>
        <ion-card-content
            class="no-padding-top "
        >
            <ion-list>

                <!-- Qualification -->

                <ion-item lines="none">
                    <ion-label>
                        <h3 class="text-white">
                            Eingesetzt als
                        </h3>
                        <p class="text-light">
                            {{ booking.qualification.label }}
                        </p>
                    </ion-label>
                </ion-item>

                <!-- Qualification -->

                <ion-item lines="none">
                    <ion-label>
                        <h3 class="text-white">
                            Arbeitszeit
                        </h3>
                        <p class="text-light">
                            {{ booking.shiftType }}: {{ datetime.formatKapitelDateTime(booking.begin, datetime.kapitelDateTimeFormatTime) }} - {{ datetime.formatKapitelDateTime(booking.until, datetime.kapitelDateTimeFormatTime) }} Uhr
                        </p>
                    </ion-label>
                </ion-item>

                <!-- Additional Info -->

                <ion-item
                    v-if="booking.shiftInformation.comment"
                    lines="none"
                >
                    <ion-label>
                        <h3 class="text-white">
                            Weitere Informationen
                        </h3>
                        <p class="text-light">
                            {{ booking.shiftInformation.comment }}
                        </p>
                    </ion-label>
                </ion-item>
            </ion-list>
            <ion-button color="light" expand="block" v-if="timesheetToDos.length>0" v-on:click="startTimesheeting">
                {{timesheetToDoButtonLabel(timesheetToDos)}}
            </ion-button>
        </ion-card-content>
    </ion-card>



    <!-- Station -->

    <ion-card>
        <ion-card-header>
            <ion-card-title>
                Station
            </ion-card-title>
        </ion-card-header>
        <ion-card-content
            class="no-padding-top"
        >
            <p>{{ booking.jobsite?.name }}</p>
            <p v-if="booking.jobsite?.additionalAmenImportantAbbreviations?.length">
                <br>
                {{ booking.jobsite?.additionalAmenImportantAbbreviations.join(", ") }}
            </p>

            <ion-list>

                <!-- Address -->

                <ion-item
                    v-if="address"
                >
                    <ion-label>
                        <h3>
                            Adresse
                        </h3>
                        <p>
                            {{ address.street }} {{ address.postalCode }}<br>
                            {{ address.city }}
                        </p>
                    </ion-label>
                </ion-item>

                <!-- Contact -->

                <ion-item
                    v-if="contact.jobsite"
                >
                    <ion-label>
                        <h3>
                            Kontakt
                        </h3>
                        <p>
                            <Contact
                                :contact="contact.jobsite"
                            />
                        </p>

                    </ion-label>
                </ion-item>

                <!-- Requester -->

                <ion-item
                    v-if="contact.requestedBy"
                >
                    <ion-label>
                        <h3>
                            Angefragt von
                        </h3>
                        <p>
                            <Contact
                                :contact="contact.requestedBy"
                            />
                        </p>
                    </ion-label>
                </ion-item>

                <!-- More Detail -->

                <ion-item
                    :detail="true"
                    detailIcon="/icons/caret-right.svg"
                    lines="none"
                    v-on:click="()=>{onJobsiteClick(props.booking.jobsite.id)}"
                >
                    <ion-label>
                        <h3>
                            Weitere Details
                        </h3>
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>
    <JobsiteDetails v-model="jobsiteDetailModal" />
    <TimesheetProcess v-model="timesheetModalValue" :selected-jobsite-id="booking?.jobsite.id"></TimesheetProcess>
</template>


<script setup lang="ts">
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList} from '@ionic/vue'
import {getAllContacts} from "@/helper/booking"
import {getImageForJobsite} from '@/helper/jobsite'
import {computed, ref, Ref} from "vue"
import {useRouter} from 'vue-router'
import Contact from "./Contact.vue"
import datetime from "@/helper/datetime/datetime"
import {timesheetToDoButtonLabel} from '@/helper/timesheet';
import {storeToRefs} from 'pinia';
import {useTimesheetStore} from "@/store/timesheet";
import JobsiteDetails from "@/views/JobsiteDetails.vue";
import TimesheetProcess from "@/views/Timesheet/TimesheetProcess.vue";


const props = defineProps({
    booking: Object
})

const router = useRouter()
const contact = computed(() => getAllContacts(props.booking))
const address = computed(() => props.booking.jobsite.address || props.booking.jobsite.house?.address || props.booking.jobsite.client?.address)

const timesheetStore = useTimesheetStore()
const { getSignableTimesheetTodosForJobsiteId } = storeToRefs(timesheetStore)

const timesheetToDos = computed(() => {
  if(props.booking.jobsite){
    return getSignableTimesheetTodosForJobsiteId.value(props.booking.jobsite.id)
  }
  return [];
})

const timesheetModalValue: Ref<boolean|undefined> = ref(undefined)
const startTimesheeting = ()=>{
    timesheetModalValue.value = true
}

const jobsiteDetailModal: Ref<number|undefined> = ref(undefined)
const onJobsiteClick = (jobsiteId: number)  => {
    jobsiteDetailModal.value = jobsiteId
}

</script>

<style scoped lang="scss">
.teaser-image {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    border-radius: 1rem;
}
</style>
