import {AInesAssistantType} from "@/graphql/generated/graphql";

import {fetchWithAuth} from "@/client";
import {AssistantStream} from "openai/lib/AssistantStream";


import OpenAI from "openai";
import {consoleErrorChat} from "@/helper/console";
import {kapitelErrorHandler} from "@/helper/error";
import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import {getOpenAIApiKey, makeMessagePersistParams} from "@/helper/chat/chatBL";
import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Response} from "openai/core";

import {KapitelToolCall} from "@/helper/chat/toolCalls";
import {useAuthStore} from "@/store/auth";

const openaiClient = async() : Promise<OpenAI> => {
    const endpoint = import.meta.env.VITE_AI_ENDPOINT
    let key: string = "";
    const parsedUrl = new URL(endpoint); // Parse the URL
    if(parsedUrl.hostname === "api.openai.com"){
        key = await getOpenAIApiKey()
    }else{
        key = useAuthStore().getToken() ?? ""
    }

    return new OpenAI(
        {
            baseURL: endpoint,
            apiKey: key,
            // apiKey,
            dangerouslyAllowBrowser: true,
            maxRetries: 2,
            timeout: 60*1000
        }
    );
}

export type MessagePersistParameters = {
    assistant: string,
    assistantType: AInesAssistantType,
    sessionId: string,
    threadId: string,
    pingPongId: string,
    runId: string|undefined,
    role: "assistant"|"user"|"tool",
    content: string,
    toolCalls: any,
    toolCallId: string|undefined,
    richResponses: any,
    isScriptedContent: boolean,
    isVoiceMode: boolean,
}

const apiErrorHandler = (e: any, kapitelErrorHandlerAlreadyHandled = false) => {
    // log to chat console
    consoleErrorChat(e)

    // handle error (toast, sentry)
    if (!kapitelErrorHandlerAlreadyHandled) {
        kapitelErrorHandler(e)
    }
}

/**
 *
 */
export const createAssistant = async (assistantType: AInesAssistantType): Promise<string> => {
    try {
        const response = await fetchWithAuth(
            import.meta.env.VITE_REST_ENDPOINT + '/aines/create-assistant' + '?' + assistantType,
            JSON.stringify({
                assistant_type: assistantType,
            }),
            'POST',
            {
                "Content-Type": 'application/json',
            }
        )
        const assistant = await response.json();
        return assistant.id
    } catch (e: any) {
        apiErrorHandler(e, true)
        throw e
    }
};

/**
 *
 */
export const createThread = async (): Promise<string> => {
    try {
        const thread = await (await openaiClient()).beta.threads.create()
        if (!thread || !(await thread).id) {
            throw new Error("create thread didn't return threadId")
        }
        return thread.id
    } catch (e: any) {
        apiErrorHandler(e, false)
        throw e
    }
}

export const performToolCallRequest = async (toolCall: KapitelToolCall): Promise<any> => {
    try {
        const param = JSON.stringify({
            id: toolCall.id,
            type: 'function',
            function: {
                name: toolCall.function,
                arguments: toolCall.arguments,
            }
        })

        const response = await fetchWithAuth(
            import.meta.env.VITE_REST_ENDPOINT + '/aines/perform-tool-call',
            param,
            "POST"
        );
        if (!response?.body) {
            throw Error("No valid response given from perform-toolcall for: " + param)
        }

        return await response.text()
    } catch (e: any) {
        apiErrorHandler(e, true)
        throw e
    }
}

export const submitToolCallResponse = async (toolCallOutputs: Array<{output: string, tool_call_id: string}>, runConfig: AssistantRunConfig, runId: string) => {
    try {
        return (await openaiClient()).beta.threads.runs.submitToolOutputsStream(
            runConfig.threadId,
            runId,
            {tool_outputs: toolCallOutputs}
        );
    } catch (e: any) {
        apiErrorHandler(e, false)
        throw e
    }
}

export const sendMessageAndRun = async (
    config: AssistantRunConfig,
    content: string,
    isScriptedContent: boolean

): Promise<AssistantStream> => {

    await sendMessage(content, config.threadId)

    makeMessagePersistParams({
        config: config,
        role: "user",
        content: content,
        isScriptedContent: isScriptedContent
    }).then(persistMessage)

    console.warn(config)
    return runThread(config.assistantId, config.threadId, await config.getAdditionalInstructions())

}

const runThread = async (assistantId: string, threadId: string, additionalInstructions: string | undefined) => {
    try {
        return (await openaiClient()).beta.threads.runs.stream(threadId, {
            assistant_id: assistantId,
            additional_instructions: additionalInstructions
        });
    } catch (e: any) {
        apiErrorHandler(e, false)
        throw e
    }
}

export const sendMessage = async (
    content: string,
    threadId: string,
    role: 'user' | 'assistant' = 'user',
) => {
    try {
        await (await openaiClient()).beta.threads.messages.create(threadId, {
            role: role,
            content: content,
        });
        return true
    } catch (e: any) {
        apiErrorHandler(e, false)
        throw e
    }
};


export const tts = async (payload: string): Promise<Response> => {
    return (await openaiClient()).audio.speech.create({
        model: 'tts-1',
        voice: 'shimmer',
        input: payload,
        // speed: 1.2
    });
}


/**
 * wraps base64 in file and calls openai API via transcribeAudioFile()
 */
export const transcribeAudioBase64 = async (b64Audio: string, mimeType: string): Promise<string | undefined> => {

    const base64ToBlob = (base64: string, mime: string): Blob => {
        const byteChars = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteChars.length; offset += 512) {
            const slice = byteChars.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: mime});
    }

    let filename = "audio.webm"

    if (mimeType === 'audio/mp4') {
        filename = "audio.mp4"
        alert(mimeType + ' unsupported by OpenAI API - use Chrome browser or native app')
        return;
    } else if (mimeType === 'audio/aac') {
        filename = "audio.m4a"
        alert(mimeType + ' unsupported by OpenAI API - use Chrome browser or native app')
        return;
    }

    const audioFile = new File([base64ToBlob(b64Audio, mimeType)], filename, {type: mimeType});

    return await transcribeAudioFile(audioFile)
}

/**
 * helper list
 */
const transcriptionIgnoreList = [
    "Untertitelung aufgrund der Amara.org-Community",
    "Untertitel im Auftrag des ZDF für funk, 2017",
    "Untertitel von Stephanie Geiges",
    "Untertitel der Amara.org-Community",
    "Untertitel im Auftrag des ZDF, 2017",
    "Untertitel im Auftrag des ZDF, 2020",
    "Untertitel im Auftrag des ZDF, 2018",
    "Untertitel im Auftrag des ZDF, 2021",
    "Untertitelung im Auftrag des ZDF, 2021",
    "Copyright WDR 2021",
    "Copyright WDR 2020",
    "Copyright WDR 2019",
    "SWR 2021",
    "SWR 2020",
    "Vielen Dank für's Zuschauen!",
    "Bis zum nächsten Mal."
]

/**
 * helper function
 */

/**
 *
 */
const transcribeAudioFile = async (file: File): Promise<string | undefined> => {
    const formData = new FormData();
    formData.append("file", file);

    const maxNumberOfTries: number = 3
    let currentNumberOfTries: number = 0
    let transcriptionText = undefined;
    while (!transcriptionText  && currentNumberOfTries<maxNumberOfTries){
        try {
            const transcription = await fetchWithAuth(
                import.meta.env.VITE_REST_ENDPOINT + '/aines/transcription',
                formData,
                "POST"
            )

            const transcriptionTextObject = await transcription.text();
            transcriptionText = (await JSON.parse(transcriptionTextObject))?.text || '';
        } catch (e: any) {
            console.log("transcription failed " + (currentNumberOfTries+1).toString() + " times.")
            transcriptionText = undefined
            apiErrorHandler(e, true)
            throw e
        }
        currentNumberOfTries++;
    }
    if(!transcriptionText){
        console.log("transcription failed.")
        return undefined
    }

    // mostly on empty audio translations we get strange responses - this fixes those cases
    const removeIgnoredTranscriptions = (transcription: string) => {
        transcriptionIgnoreList.forEach(ignoreString => {
            transcription = transcription.replace(new RegExp(ignoreString, 'g'), '');
        });
        return transcription;
    };

    // Example usage:
    transcriptionText = removeIgnoredTranscriptions(transcriptionText).trim();

    return transcriptionText
};

export const persistMessage = async (
   params: MessagePersistParameters
): Promise<any> => {
    const response = await fetchWithAuth(
        import.meta.env.VITE_REST_ENDPOINT + '/aines/persist-message' + '?' + params.assistantType,
        JSON.stringify({
            assistant: params.assistant,
            assistantType: params.assistantType,
            sessionId: params.sessionId,
            threadId: params.threadId,
            pingPongId: params.pingPongId,
            runId: params.runId,
            role: params.role,
            content: params.content,
            toolCalls: params.toolCalls,
            toolCallId: params.toolCallId,
            richResponses: params.richResponses,
            isScriptedContent: params.isScriptedContent,
            isVoiceMode: params.isVoiceMode
        }),
        "POST"
    );
    if (!response?.body) {
        throw Error("No valid response given.")
    }

    return await response.text()
}

export const getApiKey = async () => {
    const result = await query(gql`
        query GetOpenaiApiKey{
            openaiApiKey 
        }`
    )

    return result?.data.openaiApiKey
};
